<div #wrapperUi class="mb-6 overflow-auto relative min-h-52">
  @if (!isLoading() || data().length) {
    <table [dataSource]="data()" id="large-list-table" mat-table>
      @for (header of headers(); track header.key) {
        <ng-container [matColumnDef]="header.key" [sticky]="$first" [stickyEnd]="$last">
          @if (header.type === TableHeaderType.Checkbox) {
            <th class="text-center" mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                [ngClass]="{ '!hidden': data().length === 0 }"
                class="!block !ms-2"
                (change)="$event ? toggleAllRows($event.checked, header.key) : null"
                [checked]="isAllSelected(header.key)"
                [indeterminate]="isOneSelected(header.key) && !isAllSelected(header.key)"
                [aria-label]="checkboxLabel(header.key) | translate"
              >
              </mat-checkbox>
            </th>
          } @else {
            <th mat-header-cell *matHeaderCellDef class="!px-6">
              @if (header.isSortable) {
                <button
                  (keypress.enter)="updateFilterSignal(header.key)"
                  (click)="updateFilterSignal(header.key)"
                  class="flex items-center gap-2 cursor-pointer"
                  [ngClass]="{ '!ml-20': $first }"
                >
                  <p class="text-xs font-medium whitespace-nowrap" [attr.aria-label]="header.label | translate">
                    {{ header.label | translate }}
                  </p>
                  <img
                    ngSrc="assets/images/icons/double-vert-chevron.svg"
                    priority="true"
                    width="8"
                    height="10"
                    [alt]="'SORT' | translate"
                  />
                </button>
              } @else {
                <p class="text-xs font-medium whitespace-nowrap" [attr.aria-label]="header.label | translate">
                  {{ header.label | translate }}
                </p>
              }
            </th>
          }
          <td
            class="relative"
            [ngClass]="{
              'rounded-s border-s': $first,
              'rounded-e': $last,
              'rounded-grey-border':
                header.type === TableHeaderType.TextWithHeartAndVisibleIcon && !element.isAdvertised && $first,
              'rounded-green-border':
                header.type === TableHeaderType.TextWithHeartAndVisibleIcon && element.isAdvertised && $first,
              'grey-background': header.type === TableHeaderType.StatusAndSubStatus
            }"
            mat-cell
            *matCellDef="let element"
          >
            <div class="flex ps-2 items-center" [ngClass]="{ 'justify-between': $last && actions() }">
              @switch (header.type) {
                @case (TableHeaderType.ImageWithHeaderAndSubHeader) {
                  <div class="flex gap-10 ps-2 items-center">
                    @if (containsHearts()) {
                      <button
                        class="cursor-pointer"
                        (click)="element.isFavourite = !element.isFavourite; heart.emit(element.id)"
                        type="button"
                      >
                        <img
                          class="max-w-none"
                          [alt]="(element.isFavourite ? 'UNFAVOURITE' : 'FAVOURITE') | translate"
                          [ngSrc]="
                            element.isFavourite
                              ? 'assets/images/icons/heart-solid.svg'
                              : 'assets/images/icons/heart-outlined.svg'
                          "
                          priority="true"
                          width="20"
                          height="17"
                        />
                      </button>
                    }
                    <button
                      (click)="header.routerLink ? header.routerLink(element['id']) : null"
                      [ngClass]="header.routerLink ? 'cursor-pointer' : 'cursor-default'"
                      class="flex gap-4 items-center py-[0.625rem] text-left"
                      [attr.aria-label]="
                        ('DIVISIONS.DIVISION' | translate) + element.header.name + ' ' + element.header.subheader
                      "
                    >
                      @if (includeImage()) {
                        <img
                          class="h-[3.75rem] w-[3.75rem] rounded-lg"
                          [src]="element.header.imageUrl || 'assets/images/division-logo-placeholder.jpg'"
                          [alt]="'DIVISIONS.DIVISION_LOGO' | translate"
                        />
                      } @else {
                        <!--       Image Placeholder       -->
                        <div class="h-[3.75rem]"></div>
                      }
                      <div class="flex flex-col gap-[2px] overflow-hidden">
                        @if (header.maxTextLength && element.header.name.length > header.maxTextLength) {
                          <div class="flex items-center gap-1">
                            <button
                              (click)="header.routerLink ? header.routerLink(element['id']) : null"
                              [ngClass]="header.routerLink ? 'cursor-pointer' : 'cursor-default'"
                              class="text-[0.813rem] font-medium whitespace-nowrap truncate text-left"
                              [matTooltipShowDelay]="500"
                              [matTooltip]="element.header.name"
                            >
                              {{ element.header.name | truncate: header.maxTextLength }}
                            </button>
                            <img
                              class="cursor-pointer"
                              [matTooltip]="element.header.name"
                              [alt]="'MORE_INFO' | translate"
                              ngSrc="assets/images/icons/mouseover.svg"
                              priority
                              width="16"
                              height="16"
                            />
                          </div>
                        } @else {
                          <button
                            (click)="header.routerLink ? header.routerLink(element['id']) : null"
                            [ngClass]="header.routerLink ? 'cursor-pointer' : 'cursor-default'"
                            class="text-[0.813rem] font-medium whitespace-nowrap truncate text-left"
                            [matTooltipShowDelay]="500"
                            [matTooltip]="element.header.name"
                          >
                            {{ element.header.name }}
                          </button>
                        }
                        <p
                          [matTooltip]="element.header.subheader"
                          [matTooltipShowDelay]="500"
                          [attr.aria-label]="element.header.subheader"
                          class="text-[0.688rem] truncate font-light whitespace-nowrap"
                        >
                          @if (subheaderPrefix()) {
                            {{ subheaderPrefix()! | translate }}:
                          }
                          {{ element.header.subheader }}
                        </p>
                      </div>
                    </button>
                  </div>
                }
                @case (TableHeaderType.Image) {
                  <img [src]="element[header.key]" alt="Image" width="30" height="30" class="rounded-lg" />
                }
                @case (TableHeaderType.Checkbox) {
                  <mat-checkbox
                    (click)="$event.stopPropagation()"
                    (change)="emitSelectedItem(element, $event.checked, header.key)"
                    [checked]="isCheckboxSelected(element.id, header.key)"
                    [aria-label]="checkboxLabel(element) | translate"
                  >
                  </mat-checkbox>
                }
                @case (TableHeaderType.OverViewStatus) {
                  <div class="flex justify-between gap-6 pe-2">
                    <div
                      class="flex gap-2 items-center"
                      [attr.aria-label]="
                        (header.label | translate) +
                        ('UNIT_STATUS.NOT_ACTIVE' | translate) +
                        element.unitStatuses.notActiveNo +
                        ('UNIT_STATUS.AVAILABLE' | translate) +
                        element.unitStatuses.availableNo +
                        ('UNIT_STATUS.IN_PROGRESS' | translate) +
                        element.unitStatuses.inProcessNo +
                        ('UNIT_STATUS.COMPLETED' | translate) +
                        element.unitStatuses.completedNo
                      "
                    >
                      <div class="bg-black-600 unit-status">
                        <p
                          class="unit-status__text"
                          [attr.aria-label]="('UNIT_STATUS.NOT_ACTIVE' | translate) + element.unitStatuses.notActiveNo"
                        >
                          {{ element.unitStatuses.notActiveNo }}
                        </p>
                      </div>
                      <div class="bg-status-available unit-status">
                        <p
                          class="unit-status__text"
                          [attr.aria-label]="('UNIT_STATUS.AVAILABLE' | translate) + element.unitStatuses.availableNo"
                        >
                          {{ element.unitStatuses.availableNo }}
                        </p>
                      </div>
                      <div class="bg-status-inProcess unit-status">
                        <p
                          class="unit-status__text"
                          [attr.aria-label]="('UNIT_STATUS.IN_PROCESS' | translate) + element.unitStatuses.inProcessNo"
                        >
                          {{ element.unitStatuses.inProcessNo }}
                        </p>
                      </div>
                      <div class="bg-status-completed unit-status">
                        <p
                          class="unit-status__text"
                          [attr.aria-label]="('UNIT_STATUS.COMPLETED' | translate) + element.unitStatuses.completedNo"
                        >
                          {{ element.unitStatuses.completedNo }}
                        </p>
                      </div>
                    </div>
                  </div>
                }
                @case (TableHeaderType.TextWithHeartAndVisibleIcon) {
                  <div class="flex items-center">
                    <div class="py-[1rem]">
                      <div class="h-[3rem] flex items-center gap-5">
                        @if (element.header.unitStatuses !== "UNIT_STATUS.NOT_ACTIVE") {
                          <div class="flex flex-col">
                            <button class="cursor-pointer" (click)="advertised.emit(element)" type="button">
                              <img
                                class="max-w-none mb-3"
                                [alt]="(element.isAdvertised ? 'ADVERTISED' : 'UNADVERTISED') | translate"
                                [ngSrc]="
                                  element.isAdvertised
                                    ? 'assets/images/icons/eye.svg'
                                    : 'assets/images/icons/eye-dimmed.svg'
                                "
                                priority="true"
                                width="20"
                                height="17"
                              />
                            </button>
                            <button
                              class="cursor-pointer"
                              (click)="element.isFavourite = !element.isFavourite; heart.emit(element.id)"
                              type="button"
                            >
                              <img
                                class="max-w-none"
                                [alt]="(element.isFavourite ? 'UNFAVOURITE' : 'FAVOURITE') | translate"
                                [ngSrc]="
                                  element.isFavourite
                                    ? 'assets/images/icons/orange-heart.svg'
                                    : 'assets/images/icons/heart-outlined.svg'
                                "
                                priority="true"
                                width="20"
                                height="17"
                              />
                            </button>
                          </div>
                        }
                        <p
                          class="font-light text-[0.813rem] whitespace-nowrap"
                          [attr.aria-label]="(header.label | translate) + element[header.key]"
                        >
                          {{ element.header.subheader }}
                        </p>
                      </div>
                    </div>
                  </div>
                }
                @case (TableHeaderType.StatusAndSubStatus) {
                  <div class="flex w-full justify-center items-center gap-4">
                    <div class="mx-2 w-full text-center">
                      <div class="py-1 px-8 rounded-t-md" [ngClass]="getMainStatusClasses(element.header.unitStatuses)">
                        <p class="font-cerebri font-semibold text-opacity-90 text-white">
                          {{ element.header.unitStatuses | translate }}
                        </p>
                      </div>
                      <div class="py-1 px-8 rounded-b-md" [ngClass]="getSubStatusClasses(element.header.unitStatuses)">
                        <p class="font-cerebri font-semibold text-opacity-90 text-white">
                          {{ element.subStatus | translate }}
                        </p>
                      </div>
                    </div>
                  </div>
                }
                @case (TableHeaderType.TwoColumnRow) {
                  <div class="flex gap-6 w-40">
                    <div>
                      <p class="mb-2">{{ element.room }}-{{ "ROOM" | translate }}</p>
                      <p>{{ element.unitArea }} m²</p>
                    </div>
                    <div>
                      <p class="mb-2">{{ element.floor }}</p>
                      <p>{{ element.hasGarden ? "Garden" : "-" }}</p>
                    </div>
                  </div>
                }
                @case (TableHeaderType.PriceRow) {
                  <div class="flex gap-6">
                    <div>
                      <p class="mb-2">{{ "UNIT.RENT_PER_MONTH" | translate }}</p>
                      <p>{{ "UNIT.RENT_PER_METER" | translate }}</p>
                    </div>
                    <div>
                      <p class="mb-2">{{ element.baseRent }} {{ element.defaultCurrencyISO }}</p>
                      <p>{{ element.basePricePerSquaremeter }} {{ element.defaultCurrencyISO }}</p>
                    </div>
                  </div>
                }
                @default {
                  @if (header.partialTranslationKey) {
                    @if (
                      header.maxTextLength &&
                      (element[header.key] | partialTranslate: header.partialTranslationKey).length >
                        header.maxTextLength
                    ) {
                      <div class="flex items-center gap-1">
                        <p
                          [ngClass]="getRowAppliedClasses(element, header)"
                          [attr.aria-label]="
                            (header.label | translate) +
                            ' ' +
                            (element[header.key] | partialTranslate: header.partialTranslationKey)
                          "
                          class="whitespace-nowrap"
                        >
                          {{
                            element[header.key]
                              | partialTranslate: header.partialTranslationKey
                              | truncate: header.maxTextLength
                          }}
                        </p>
                        <img
                          class="cursor-pointer"
                          [matTooltip]="element[header.key] | partialTranslate: header.partialTranslationKey"
                          [alt]="'MORE_INFO' | translate"
                          ngSrc="assets/images/icons/mouseover.svg"
                          priority
                          width="16"
                          height="16"
                        />
                      </div>
                    } @else {
                      <p
                        [ngClass]="getRowAppliedClasses(element, header)"
                        [attr.aria-label]="
                          (header.label | translate) +
                          ' ' +
                          (element[header.key] | partialTranslate: header.partialTranslationKey)
                        "
                        class="whitespace-nowrap"
                      >
                        {{ element[header.key] | partialTranslate: header.partialTranslationKey }}
                      </p>
                    }
                  } @else if (header.needsTranslation) {
                    @if (header.maxTextLength && (element[header.key] | translate).length > header.maxTextLength) {
                      <div class="flex items-center gap-1">
                        <p
                          [ngClass]="getRowAppliedClasses(element, header)"
                          [attr.aria-label]="(header.label | translate) + ' ' + (element[header.key] | translate)"
                          class="whitespace-nowrap"
                        >
                          {{ element[header.key] | translate | truncate: header.maxTextLength }}
                        </p>
                        <img
                          class="cursor-pointer"
                          [matTooltip]="element[header.key] | translate"
                          [alt]="'MORE_INFO' | translate"
                          ngSrc="assets/images/icons/mouseover.svg"
                          priority
                          width="16"
                          height="16"
                        />
                      </div>
                    } @else {
                      <p
                        [ngClass]="getRowAppliedClasses(element, header)"
                        [attr.aria-label]="(header.label | translate) + ' ' + (element[header.key] | translate)"
                        class="whitespace-nowrap"
                      >
                        {{ element[header.key] | translate }}
                      </p>
                    }
                  } @else {
                    @if (header.maxTextLength && element[header.key].length > header.maxTextLength) {
                      <div class="flex items-center gap-1">
                        <p
                          [ngClass]="getRowAppliedClasses(element, header)"
                          [attr.aria-label]="(header.label | translate) + ' ' + element[header.key]"
                          class="whitespace-nowrap"
                        >
                          {{ element[header.key] | truncate: header.maxTextLength }}
                        </p>
                        <img
                          class="cursor-pointer"
                          [matTooltip]="element[header.key]"
                          [alt]="'MORE_INFO' | translate"
                          ngSrc="assets/images/icons/mouseover.svg"
                          priority
                          width="16"
                          height="16"
                        />
                      </div>
                    } @else {
                      <p
                        [ngClass]="getRowAppliedClasses(element, header)"
                        [attr.aria-label]="(header.label | translate) + ' ' + element[header.key]"
                        class="whitespace-nowrap"
                      >
                        {{ element[header.key] }}
                      </p>
                    }
                  }
                }
              }
              @if ($last && actions()) {
                <button
                  [matMenuTriggerFor]="menu"
                  [attr.aria-label]="'DROPDOWN_MENU' | translate"
                  class="ms-5 cursor-pointer flex items-center justify-center rounded border bg-white border-black-300"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>
                <mat-menu #menu="matMenu">
                  @for (action of actions(); track action.labelFn) {
                    @if (!action.isVisible || action.isVisible.call(this, element)) {
                      <button
                        [attr.aria-label]="action.labelFn(element) | translate"
                        (keypress.enter)="action.callbackFn(element)"
                        (click)="action.callbackFn(element)"
                        mat-menu-item
                      >
                        {{ action.labelFn(element) | translate }}
                      </button>
                    }
                  }
                </mat-menu>
              } @else {
                <td class="relative !px-6" mat-cell *matCellDef="let element">
                  <p
                    class="font-light text-[0.813rem] whitespace-nowrap"
                    [attr.aria-label]="(header.label | translate) + element[header.key]"
                  >
                    @if (header.partialTranslationKey) {
                      {{ element[header.key] | partialTranslate: header.partialTranslationKey }}
                    } @else if (header.needsTranslation) {
                      {{ element[header.key] | translate }}
                    } @else {
                      {{ element[header.key] }}
                    }
                  </p>
                  <div class="absolute top-1/2 h-1/2 -translate-y-1/2 end-2 w-[1px] bg-black-300"></div>
                </td>
              }
            </div>
            <div
              [ngClass]="{
                hidden:
                  header.type === TableHeaderType.ImageWithHeaderAndSubHeader ||
                  $last ||
                  $first ||
                  headers().indexOf(header) === headers().length - 2
              }"
              class="absolute top-1/2 h-1/2 -translate-y-1/2 end-2 w-[1px] bg-black-300"
            ></div>
          </td>
        </ng-container>
      }
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  }
  @if (!isLoading() && !data().length) {
    <div class="bg-black-50 flex items-center justify-center py-8 rounded border border-black-300">
      <p class="text-xs font-light">{{ "NO_RESULTS" | translate }}</p>
    </div>
  }
  @if (isLoading()) {
    <est-loading-overlay appliedClasses="!bg-white/60"></est-loading-overlay>
  }
</div>
