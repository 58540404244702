import { NgClass, NgOptimizedImage } from "@angular/common";
import { booleanAttribute, Component, effect, EventEmitter, input, Output } from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatMenuModule } from "@angular/material/menu";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatTableModule } from "@angular/material/table";
import { MatTooltip } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";
import { BaseTableComponent } from "../abstracts/base-table/base-table.component";
import { LoadingOverlayComponent } from "../loading-overlay/loading-overlay.component";
import { ILargeListTableInput } from "../interfaces/large-list-table-input.interface";
import { PartialTranslatePipe } from "../../core/pipes/partial-translate.pipe";
import { TruncatePipe } from "@root/core/pipes/truncate.pipe";
import { UnitStatus } from "@root/data/market/division/enums/unit-status.enum";
import {
  getMainStatusClasses,
  getSubStatusClasses,
} from "@root/data/market/units/utilities/residential-units.utilities";

@Component({
  selector: "est-large-list-table",
  standalone: true,
  imports: [
    MatTableModule,
    TranslateModule,
    MatIconModule,
    NgClass,
    NgOptimizedImage,
    MatMenuModule,
    MatProgressSpinner,
    MatTooltip,
    LoadingOverlayComponent,
    PartialTranslatePipe,
    MatCheckbox,
    TruncatePipe,
  ],
  templateUrl: "./large-list-table.component.html",
  styleUrl: "./large-list-table.component.scss",
})
export class LargeListTableComponent extends BaseTableComponent {
  // When using this component. specify header and unitStatuses in data, then any property you like.
  // By design, header will appear in the first column and unitStatuses will appear in the last column.
  // Rest of the properties will appear in the middle by the order you provided
  protected readonly getSubStatusClasses = getSubStatusClasses;
  protected readonly getMainStatusClasses = getMainStatusClasses;
  // @Input() typeSpecificData!: any; // Consider using a more specific type or interface
  @Output() heart = new EventEmitter<any>();
  @Output() advertised = new EventEmitter<any>();
  unitStatus = UnitStatus;
  includeImage = input(true, {
    transform: booleanAttribute,
  });
  override data = input.required<ILargeListTableInput[]>();
  subheaderPrefix = input<string | null>(null);
  containsHearts = input(false, {
    transform: booleanAttribute,
  });
  readonly #addMarkedCheckboxesToSelectionEffect$ = effect(() => {
    this.addMarkedCheckboxesToSelection();
  });
}
